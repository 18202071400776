import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"

import BackgroundSection from "./background.js"

// import BackgroundImage from 'gatsby-background-image'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Container>
      <h1>Hi people</h1>
      <p>Welcome to your new Gatsby site.</p>
      <p>Now go build something great.</p>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <Image />
      </div>
      <Link to="/page-2/">Go to page 2</Link>
    </Container>
    

    <div className="masthead fixed" style={{ backgroundImage: `url(${BackgroundSection})` }}>
          <Container className="container h-100">
            <Row className="row h-100 align-items-center">
              <Col md="12" className="text-center">
                <h1 className="font-weight-light">Welcome to Nautilus Designs</h1>
              </Col>
            </Row>
          </Container>
        </div>
  </Layout>
)

export default IndexPage
